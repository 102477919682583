import React from 'react';
import BSButton from 'react-bootstrap/Button';

interface ButtonProps {
    id?: string
    caption: string
    inverse: boolean
    disabled?: boolean
    onClick: () => void
}

const Button: React.FC<ButtonProps> = ({ id, caption, inverse, disabled, onClick }) => {
    return (
        <BSButton id={id} className={inverse ? "inverse" : ""} disabled={disabled} onClick={onClick}>{caption}</BSButton>
    )
}

export default Button