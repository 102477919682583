import React from "react"
import ModalBS from "react-bootstrap/Modal"
import Modal, { CancelButton, OkButton } from "."

interface ModalOkProps {
    name: string
    title: string
    size?: "sm" | "lg" | "xl"
    visible: boolean
    onOk: () => void
    onClose?: () => void
    labelOk?: string
    labelClose?: string
    children: React.ReactNode
}

const ModalOk: React.FC<ModalOkProps> = ({ name, title, size, visible, onOk, onClose, labelOk, labelClose, children }) => (
    <Modal title={title} name={name} size={size} visible={visible} onClose={onClose}>
        <ModalBS.Body>{children}</ModalBS.Body>
        <ModalBS.Footer>
            {onClose && <CancelButton name={name} label={labelClose} onCancel={onClose} />}
            <OkButton name={name} label={labelOk} onOk={onOk} />
        </ModalBS.Footer>
    </Modal>
)

export default ModalOk
