import React from 'react'
import RemoveIcon from '../../images/remove.svg'
import Icon from '../Icon'

interface RemoveToolProps {
    onClick: () => void
}

export const RemoveTool: React.FC<RemoveToolProps> = ({ onClick }) => (
    <Icon src={RemoveIcon} onClick={onClick} />
)
