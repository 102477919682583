import React from "react"
import BSForm from "react-bootstrap/Form"
import styled from "styled-components"
import ToggleSwitch from "../ToggleSwitch"

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`

interface ToggleSwitchFieldProps {
    name: string
    label: string
    value?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    readonly?: boolean
}

const ToggleSwitchField: React.FC<ToggleSwitchFieldProps> = ({ name, label, value, readonly, onChange }) => {
    return (
        <BSForm.Group>
            <Header>
                <BSForm.Label>{label}</BSForm.Label>
                <ToggleSwitch id={name} value={value} readonly={readonly} onChange={onChange} />
            </Header>
        </BSForm.Group>
    )
}

export default ToggleSwitchField
